import React, { Component, useReducer } from 'react';
import {Collapse,FooterToggler,FooterBrand,Nav,NavItem,NavLink,UncontrolledDropdown,
 Button, Input, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, Container, Label, InputGroup} from "reactstrap";
import axios from 'axios';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import Instagram from '../images/instagram.png'
import Facebook from '../images/facebook.png'
import Twitter from '../images/twitter.png'
import Logo from '../images/logos/logo.jpg'

  class Footer extends Component{
    static propTypes = {
      cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) { 
      super(props);
      this.state = {

      };

    }

    componentDidMount() {
      
    }




    render() {
      return (
        <div  style={{minHeight: '250px', backgroundColor: '#EEECEC'}}>
          <Container>
            <Row style={{textAlign: 'left', color: ''}}>
              <Col sm='3'>
                <br/><br/>
                <h6 style={{fontWeight: 'bold', color: '#000000'}}>Get In Touch</h6>
                <br/>
                <a href="mailto:bm@libertymakuyana.com" style={{color: 'inherit'}}>bm@libertymakuyana.com</a>
                <br/><br/>
                <a href="tel:+447915559215" style={{color: 'inherit'}}>+44 7915 559 215</a>
                <br/><br/>
                <a href="tel:+263771938725" style={{color: 'inherit'}}>+263 771 938 725</a>
              </Col>
              <Col>
                <br/><br/>
                <h6 style={{fontWeight: 'bold', color: '#000000'}}>Quick Links</h6>
                <br/>
                <a href='/' style={{color: 'inherit'}}>
                  Home
                </a>
                <br/><br/>
                {/* <a href='/seminars' style={{color: 'inherit'}}>
                  Seminars
                </a>
                <br/><br/> */}
                <a href='/about' style={{color: 'inherit'}}>
                  About
                </a>
                <br/><br/>
                <a href='/videos' style={{color: 'inherit'}}>
                  Videos
                </a>
                <br/><br/>
                <a href='/programs' style={{color: 'inherit'}}>
                  Programs
                </a>
                <br/><br/>
                <a href='/virtual-bookings' style={{color: 'inherit'}}>
                  Virtual Bookings
                </a>
              </Col>
              <Col>
                <br/><br/>
                <h6 style={{fontWeight: 'bold', color: '#000000'}}>Social Media</h6>
                <br/>
                <a href='https://www.facebook.com/beyondmotivationliberty' target='_blank' style={{color: 'inherit'}}>
                  Facebook
                </a>
                <br/><br/>
                <a href='https://www.instagram.com/liberty_beyondmotivation/' target='_blank' style={{color: 'inherit'}}>
                  Instagram
                </a>
              </Col>
              <Col sm='4'>
                <br/><br/>
                <h6 style={{fontWeight: 'bold', color: '#000000'}}>Programs</h6>
                <br/>
                <a href='/registration/How To Speak Effectively (Group)' style={{color: 'inherit'}}>
                  How to Speak Effectively (Group)
                </a>
                <br/><br/>
                <a href='/registration/How To Speak Effectively (One on one)' style={{color: 'inherit'}}>
                  How to Speak Effectively (One on One)
                </a>
                <br/><br/>
                <a href='/registration/How To Speak Effectively for kids' style={{color: 'inherit'}}>
                  How to Speak Effectively for Kids
                </a>
                <br/><br/>
                <a href='/registration/One on One Interview Coaching' style={{color: 'inherit'}}>
                  One on One Interview Coaching
                </a>
                <br/><br/>
                <a href='/registration/The Mentorship Class' style={{color: 'inherit'}}>
                  The Mentorship Class
                </a>
              </Col>
            </Row>
            <br/>
            <Row style={{marginTop: '20px'}}>
                <Col>
                  <Col sm='3' style={{alignItems: 'left', textAlign: 'left', justifyContent: 'left'}}>
                    <Row style={{marginTop: '15px'}}>
                      <Col xs='5'>
                        <a href='https://www.instagram.com/liberty_beyondmotivation/' target='_blank'><img src={Instagram} style={{width: '80%'}} /></a>
                      </Col>
                      <Col xs='5'>
                        <a href='https://www.facebook.com/beyondmotivationliberty' target='_blank'><img src={Facebook} style={{width: '80%'}} /></a>
                      </Col>
                    </Row>
                  </Col>
                </Col>
                <Col>
                  Copyright 2023 &copy; Liberty Makuyana
                </Col>
            </Row>
            <br/>
          </Container>
        </div>
      );
    }

  };
  
  export default withCookies(Footer);
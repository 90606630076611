import React, { Component, useReducer } from 'react';
import {Collapse,Nav,NavItem,NavLink,UncontrolledDropdown,
 Button, Input, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, Container, Label, InputGroup} from "reactstrap";
import axios from 'axios';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import {Helmet} from 'react-helmet'
import {Backend_Url} from './backend_url'
import * as emailjs from 'emailjs-com'


class VirtualBooking extends Component{
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) { 
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      company_name: '',
      website: '',
      date_of_event: '',
      specific_time_of_event: '',
      address_of_event: '',
      additional_information: ''
    };

    this.HandleChange = (e) =>{
      this.setState({[e.target.name]: e.target.value});
    };

    this.Submit = (e) => {
      e.preventDefault()

      if (this.state.firstname == ''){
        alert('Firstname is required.')
      }else if (this.state.lastname == ''){
        alert('Lastname is required.')
      }else if (this.state.email == ''){
        alert('Email is required.')
      }else if(this.state.phone == ''){
        alert('Phone is required.')
      }else if(this.state.company_name == ''){
        alert('Company name is required.')
      }else if(this.state.date_of_event == ''){
        alert('Date of event is required.')
      }else if(this.state.specific_time_of_event == ''){
        alert('Specific time of event is required.')
      }else if(this.state.address_of_event == ''){
        alert('Address of event is required.')
      }else if(this.state.additional_information == ''){
        alert('Additional information is required.')
      }else{

        let message = 'Booking information: <br/><br/>' +
          'Firstname: ' + this.state.firstname + '<br/>' +
          'Lastname: ' + this.state.lastname + '<br/>' +
          'Email: ' + this.state.email + '<br/>' +
          'Phone: ' + this.state.phone + '<br/>' +
          'Company Name: ' + this.state.company_name + '<br/>' +
          'Website: ' + this.state.website + '<br/>' +
          'Date of Event: ' + this.state.date_of_event + '<br/>' +
          'Specific Time of Event: ' + this.state.specific_time_of_event + '<br/>' +
          'Address of Event: ' + this.state.address_of_event + '<br/>' +
          'Additional Information: ' + this.state.additional_information

        let templateParams = {
          from_name: this.state.firstname + ' ' + this.state.lastname,
          to_name: 'Liberty Makuyana',
          reply_to: this.state.email,
          subject: 'Virtual Booking',
          message_html: message,
        }

        try{
          emailjs.send(
            'service_wtne6p1',
            'template_jd4yf7n',
            templateParams,
            'user_GWbUKR3vdLVuDdeyBK5iL'
          )
          alert('Booking information submitted successfully.')
          window.location.reload()
        }catch(err){
          alert('Something went wrong, please try again.')
        }
      }
    }
  }

  componentDidMount() {

  }




  render() {
    return(
      <div style={{minHeight: '550px', backgroundColor: '#F5F5F5'}}>
        <Helmet>
          <title>Virtual Booking | Liberty Makuyana</title>
          {/* <meta name="description" content="" /> */}
        </Helmet>
        <br/><br/>
        <Container>
            <h3 style={{fontWeight: 'bold', color: '#D8A015'}}>Virtual Booking</h3>
            <br/><br/>
            <span style={{fontWeight: 'bold', color: '#020B30'}}>
                Would you like Liberty Makuyana to speak at your next virtual event? Please fill in the below details. 
            </span>
            <br/><br/><br/>
            <Row style={{textAlign: 'left'}}>
              <Col>
                Firstname <span style={{color: 'red'}}>*</span>
                <Input type="text" name="firstname" style={{}} placeholder=""
                value={this.state.firstname} onChange={this.HandleChange} />
              </Col>
              <Col>
                Lastname <span style={{color: 'red'}}>*</span>
                <Input type="text" name="lastname" style={{}} placeholder=""
                value={this.state.lastname} onChange={this.HandleChange} />
              </Col>
            </Row>
            <br/><br/>
            <Row style={{textAlign: 'left'}}>
              <Col>
                Email <span style={{color: 'red'}}>*</span>
                <Input type="text" name="email" style={{}} placeholder=""
                value={this.state.email} onChange={this.HandleChange} />
              </Col>
              <Col>
                Phone <span style={{color: 'red'}}>*</span>
                <Input type="text" name="phone" style={{}} placeholder=""
                value={this.state.phone} onChange={this.HandleChange} />
              </Col>
            </Row>
            <br/><br/>
            <Row style={{textAlign: 'left'}}>
              <Col>
                Company Name
                <Input type="text" name="company_name" style={{}} placeholder=""
                value={this.state.company_name} onChange={this.HandleChange} />
              </Col>
              <Col>
                Website
                <Input type="text" name="website" style={{}} placeholder=""
                value={this.state.website} onChange={this.HandleChange} />
              </Col>
            </Row>
            <br/><br/>
            <Row style={{textAlign: 'left'}}>
              <Col>
                Date of event <span style={{color: 'red'}}>*</span>
                <Input type="date" name="date_of_event" style={{}} placeholder=""
                value={this.state.date_of_event} onChange={this.HandleChange} />
              </Col>
              <Col>
                Specific time of event
                <Input type="time" name="specific_time_of_event" style={{}} placeholder=""
                value={this.state.specific_time_of_event} onChange={this.HandleChange} />
              </Col>
            </Row>
            <br/><br/>
            <Row style={{textAlign: 'left'}}>
              <Col>
                Address of event <span style={{color: 'red'}}>*</span>
                <Input type="text" name="address_of_event" style={{}} placeholder=""
                value={this.state.address_of_event} onChange={this.HandleChange} />
              </Col>
            </Row>
            <br/><br/>
            <Row style={{textAlign: 'left'}}>
              <Col>
                Additional information?
                <Input type="textarea" rows='5' name="additional_information" style={{}} placeholder=""
                value={this.state.additional_information} onChange={this.HandleChange} />
              </Col>
            </Row>
            <br/>
            <Button onClick={this.Submit} style={{backgroundColor: '#D8A015', color: '#ffffff', width: '200px', border: 'none', borderRadius: '20px'}}>
              Submit
            </Button>
        </Container>
        <br/><br/><br/>
      </div>
    );
  }

};
  
export default withCookies(VirtualBooking);
import React, { Component, useReducer } from 'react';
import {Collapse,Nav,NavItem,NavLink,UncontrolledDropdown,
 Button, Input, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, Container, Label, InputGroup} from "reactstrap";
import axios from 'axios';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import {Helmet} from 'react-helmet'
import {Backend_Url} from './backend_url'
import * as emailjs from 'emailjs-com'
import { PayPalButton } from "react-paypal-button-v2";


class Payments extends Component{
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) { 
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      phone: '',
      email: '',
      program: this.props.match.params.program_name,
      price: Number,
      original_price: Number,
    };

    this.HandleChange = (e) =>{
      // if (e.target.name == 'price'){
      //   var value = e.target.value
      //   if (value > this.state.original_price){
      //     alert('Payment cannot be greater than the program price ($' + this.state.original_price.toString() + ')')
      //     this.setState({price: this.state.original_price})
      //   }else{
      //     this.setState({[e.target.name]: e.target.value}); 
      //   }
      // }else{
      //   this.setState({[e.target.name]: e.target.value});
      // }
      this.setState({[e.target.name]: e.target.value});
    };

    this.SubmitRegistrationInfo = () => {
      
      let message = 'Registration information:<br/><br/>' +
                    'Firstname: ' + this.state.firstname + '<br/>' +
                    'Lastname: ' + this.state.lastname + '<br/>' +
                    'Phone: ' + this.state.phone + '<br/>' +
                    'Email: ' + this.state.email + '<br/>' +
                    'Program: ' + this.state.program

      let templateParams = {
        from_name: this.state.firstname + ' ' + this.state.lastname,
        to_name: 'Liberty Makuyana',
        reply_to: this.state.email,
        subject: 'Registration for ' + this.state.program,
        message: message,
        program: this.state.program
       }

       try{
        emailjs.send(
          'service_wtne6p1',
          'template_5tfmd05',
          templateParams,
          'user_GWbUKR3vdLVuDdeyBK5iL'
         )
         alert('Program registration completed successfully.')
         window.location.reload()
       }catch(err){
          this.SubmitRegistrationInfo()
       }
    }
  }

  componentDidMount() {
    var program = this.props.match.params.program_name
    var price = null 

    if (program == 'How To Speak Effectively (Group)'){
      price = 50
    }else if (program == 'How To Speak Effectively (One on one)'){
      price = 90
    }else if (program == 'How To Speak Effectively for kids'){
      price = 55
    }else if (program == 'One on One Interview Coaching'){
      price = 50
    }else if (program == 'The Mentorship Class'){
      price = 30
    }else if (program == 'Accounting for Success'){
      price = 50
    }else if (program == "Becoming One of The Greats"){
      price = 7
    }

    this.setState({price: price, original_price: price})
  }




  render() {
      var program = this.state.program
      var pm = ''

      if (program == 'The Mentorship Class'){
        pm = '/Month'
      }

    return(
      <div style={{minHeight: '550px', backgroundColor: '#FFFFFF'}}>
        <Helmet>
          <title>{program} | Liberty Makuyana</title>
          {/* <meta name="description" content="" /> */}
        </Helmet>
        <br/><br/><br/>
        <Container>
            <h3 style={{fontWeight: 'bold', color: '#020B30'}}>REGISTRATION FOR {program.toUpperCase()}</h3>
            <br/>
            <p style={{color: '#7A7A7A'}}>
                Price: ${this.state.original_price}{pm}
            </p>
            <br/><br/>
            <span style={{fontWeight: 'bold', color: '#020B30'}}>
                Information
            </span>
            <br/><br/><br/>
            <Row style={{textAlign: 'left'}}>
              <Col sm='6'>
                Firstname <span style={{color: 'red'}}>*</span>
                <Input type="text" name="firstname" style={{}} placeholder=""
                value={this.state.firstname} onChange={this.HandleChange} />
              </Col>
              <Col>
                Lastname <span style={{color: 'red'}}>*</span>
                <Input type="text" name="lastname" style={{}} placeholder=""
                value={this.state.lastname} onChange={this.HandleChange} />
              </Col>
            </Row>
            <br/><br/>
            <Row style={{textAlign: 'left'}}>
              <Col sm='6'>
                Phone <span style={{color: 'red'}}>*</span>
                <Input type="number" name="phone" style={{}} placeholder=""
                value={this.state.phone} onChange={this.HandleChange} />
              </Col>
              <Col>
                Email <span style={{color: 'red'}}>*</span>
                <Input type="text" name="email" style={{}} placeholder=""
                value={this.state.email} onChange={this.HandleChange} />
              </Col>
            </Row>
            <br/><br/>
            <Row style={{textAlign: 'left'}}>
              <Col sm='6'>
                Payment <span style={{color: 'grey', fontSize: '12px'}}>(customizable)</span>
                <Input type="number" name="price" style={{}} placeholder=""
                value={this.state.price} onChange={this.HandleChange} />
              </Col>
              <Col>

              </Col>
            </Row>
            <br/><br/>
            <p style={{color: '#7A7A7A'}}>
                Select your payment method below:
            </p>
            <PayPalButton
                amount={this.state.price}
                currency="USD"
                // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                onSuccess={(details, data) => {
                    this.SubmitRegistrationInfo()
                }}
                options={{
                    currency: "USD",
                    clientId: "AcFw1EZPIKr4qdjzE6anILgEQcQUHK8SQXSMVKzjMId5q6ra4VTL7q1PXiMBYVDnFuqr3Q0jWwOuCpJt"
                }}
            />
        </Container>
        <br/><br/><br/>
      </div>
    );
  }

};
  
export default withCookies(Payments);
import React, { Component, useReducer } from 'react';
import {Collapse,Nav,NavItem,NavLink,UncontrolledDropdown,
 Button, Input, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, Container, Label, InputGroup} from "reactstrap";
import axios from 'axios';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import {Helmet} from 'react-helmet'
import {Backend_Url} from './backend_url'


class AddProgram extends Component{
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) { 
    super(props);
    this.state = {
        name: '',
        speakers: '',
        details: '',
        image: null
    };

    this.HandleChange = (e) =>{
      this.setState({[e.target.name]: e.target.value});
    };

    this.UploadFile = (e) => {
      this.setState({image: e.target.files[0]})
    }

    this.AddProgram = () => {
      const { cookies } = this.props;
  
      var data = new FormData()
      data.append('user_access_token', cookies.get('token'))
      data.append('name', this.state.name)
      data.append('speakers', this.state.speakers)
      data.append('details', this.state.details)
      data.append('image', this.state.image)
  
      axios.post(Backend_Url + 'addProgram', data, { headers: { 'Bypass-Tunnel-Reminder': 'True' }  })
      .then((res) => {
        alert('Seminar added successfully.')
        window.location.reload()
      }).catch((error) => {
        alert('Something went wrong while trying to add your seminar. Please try again.')
      })
    }
  }

  componentDidMount() {

  }




  render() {
    return(
      <div style={{minHeight: '550px', backgroundColor: '#F5F5F5'}}>
        <Helmet>
          <title>Libert Makuyana | Add Seminar</title>
          {/* <meta name="description" content="" /> */}
        </Helmet>
        <Container>
          <br/>
          <h4>
            Add Seminar
          </h4>
          <br/><br/>
          <Row>
            <Col>
              <Input name='name' value={this.state.name} type='text' onChange={this.HandleChange} placeholder='Program name'
              style={{border: 'none', borderBottom: '1px solid silver'}}/>
            </Col>
            <Col>
              <Input name='speakers' value={this.state.speakers} type='text' onChange={this.HandleChange} placeholder='Program speakers'
              style={{border: 'none', borderBottom: '1px solid silver'}}/>
            </Col>
          </Row>
          <br/><br/>
          <Row>
            <Col sm='6' style={{textAlign: 'left', color: 'grey'}}>
              Select Seminar Image <br/><br/>
              <Input type='file' onChange={this.UploadFile}
              style={{border: 'none'}}/>
            </Col>
            <Col>
              <textarea name='details' value={this.state.details} type='text' onChange={this.HandleChange} placeholder='Program details'
              style={{border: 'none', borderBottom: '1px solid silver', width: '100%'}} rows='5'></textarea>
            </Col>
          </Row>
          <br/><br/><br/>
          <Row>
            <Col>

            </Col>
            <Col>
                <Button onClick={this.AddProgram} style={{border: 'none', borderRadius: '20px', width: '60%', backgroundColor: '#020B30', color: '#ffffff'}}>
                  Add Seminar
                </Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

};
  
export default withCookies(AddProgram);
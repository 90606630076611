import React, { Component, useReducer } from 'react';
import {Collapse,Nav,NavItem,NavLink,UncontrolledDropdown,
 Button, Input, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, Container, Label, InputGroup} from "reactstrap";
import axios from 'axios';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import {Helmet} from 'react-helmet'
import {Backend_Url} from './backend_url'
import Image from '../images/image_4.jpeg'


class About extends Component{
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) { 
    super(props);
    this.state = {

    };

    this.HandleChange = (e) =>{
      this.setState({[e.target.name]: e.target.value});
    };
  }

  componentDidMount() {

  }




  render() {
    return(
      <div style={{minHeight: '550px', backgroundColor: '#F5F5F5'}}>
        <Helmet>
          <title>About | Liberty Makuyana</title>
          {/* <meta name="description" content="" /> */}
        </Helmet>
        <br/><br/>
        <Container>
          <h3 style={{fontWeight: 'bold', color: '#D8A015'}}>About</h3>
          <br/><br/>
          <Row>
            <Col sm='6'>
              <img src={Image} style={{width: '100%'}} />
            </Col>
            <Col>
              <span style={{fontWeight: 'bold', color: '#020B30'}}>
                ‘We don’t do this to earn a living, we do this because it’s why we are living’ -	Liberty Makuyana
              </span>
              <br/><br/>
              <p style={{textAlign: 'left'}}>
                Liberty Makuyana is a seasoned speaker with a message that transforms the heart of individuals and organisations with his unconventional frame of thought and articulate delivery. His mantra is ‘Beyond Motivation’ in which he teaches about living within the totality of one’s God given purpose. 
                <br/><br/>
                Liberty’s vision and passion is driven by the knowledge that there is a reason why every single person was created and it is in the fulfilment of this purpose, does life find meaning. These principles go beyond individual life and apply to organisations, communities, nations and the world at large. 
                <br/><br/>
                Liberty is a Chartered Accountant, a published Author, a Business owner and above all, a devout Christian.  
                <br/><br/>
                <span style={{fontWeight: 'bold', color: '#020B30'}}>Facebook:</span> <a href='https://www.facebook.com/beyondmotivationliberty' target='_blank'>https://www.facebook.com/beyondmotivationliberty</a>
                <br/>
                <span style={{fontWeight: 'bold', color: '#020B30'}}>Instagram:</span> <a href='https://www.instagram.com/liberty_beyondmotivation/' target='_blank'>https://www.instagram.com/liberty_beyondmotivation/</a>
              </p>
            </Col>
          </Row>
        </Container>
        <br/><br/><br/>
      </div>
    );
  }

};
  
export default withCookies(About);
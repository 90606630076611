import React, { Component, useReducer } from 'react';
import {Collapse,Nav,NavItem,NavLink,UncontrolledDropdown,
 Button, Input, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, Container, Label, InputGroup} from "reactstrap";
import axios from 'axios';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import {Helmet} from 'react-helmet'
import {Backend_Url} from './backend_url'
import Image from '../images/image_5.jpeg'


class BeyondMotivation extends Component{
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) { 
    super(props);
    this.state = {
      videos: [],
      loading: true
    };

    this.HandleChange = (e) =>{
      this.setState({[e.target.name]: e.target.value});
    };

    this.DeleteVideo = (video_id) => {
      const { cookies } = this.props;
  
      var data = new FormData()
      data.append('user_access_token', cookies.get('token'))
      data.append('video_id', video_id)
    
      axios.post(Backend_Url + 'deleteVideo', data, { headers: { 'Bypass-Tunnel-Reminder': 'True' }  })
      .then((res) => {
        alert('Video deleted successfully.')
        
        window.location.reload()
      }).catch((error) => {
        alert('Something went wrong while trying to delete your video. Please try again.')
      })      
    }
  }

  componentDidMount() {
    const { cookies } = this.props;

    var data = new FormData()
    data.append('user_access_token', cookies.get('token'))

    axios.post(Backend_Url + 'videos', data, { headers: { 'Bypass-Tunnel-Reminder': 'True' }  })
    .then((res) => {
      let result = res.data
      this.setState({videos: result, loading: false})
    }).catch((error) => {
      console.log(error)
    })
  }




  render() {
    if(this.state.loading == true){
      return(
        <div style={{minHeight: '550px', backgroundColor: '#F5F5F5'}}>
          <Helmet>
            <title>Beyond Motivation | Liberty Makuyana</title>
            {/* <meta name="description" content="" /> */}
          </Helmet>
          <br/><br/>
          <Container>
            <h3 style={{fontWeight: 'bold', color: '#D8A015'}}>Beyond Motivation</h3>
            <br/>
            <span style={{fontWeight: 'bold', color: '#020B30'}}>
                Watch Liberty Makuyana’s videos
            </span>
            <br/><br/>
            <h5>
              Loading ...
            </h5>
          </Container>
          <br/><br/><br/>
        </div>
      );
    }

    var videos_map = this.state.videos.map((video, index) => {

      const { cookies } = this.props;
      if(cookies.get('token') == null){
        return<Col sm='6'>
            <iframe src={video.frame_src} width="100%" height="314" style={{border: 'none', overflow: 'hidden', marginBottom: '15px'}} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
        </Col>
      }else{
        return<Col sm='6'>
          <iframe src={video.frame_src} width="100%" height="314" style={{border: 'none', overflow: 'hidden', marginBottom: '15px'}} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
          <br/>
          <Row>
            <Col>
            
            </Col>
            <Col>
              <Button onClick={() => this.DeleteVideo(video._id.$oid)} style={{border: 'none', backgroundColor: '#D8A015', color: '#ffffff', width: '200px', borderRadius: '20px'}}>
                Delete Video
              </Button>
            </Col>
          </Row>
          <br/><br/>
        </Col>
      }
    })

    return(
      <div style={{minHeight: '550px', backgroundColor: '#F5F5F5'}}>
        <Helmet>
          <title>Beyond Motivation | Liberty Makuyana</title>
          {/* <meta name="description" content="" /> */}
        </Helmet>
        <br/><br/>
        <Container>
            <h3 style={{fontWeight: 'bold', color: '#D8A015'}}>Beyond Motivation</h3>
            <br/>
            <span style={{fontWeight: 'bold', color: '#020B30'}}>
                Watch Liberty Makuyana’s videos
            </span>
            <br/><br/>
            <Row>
              {videos_map}
            </Row>
            <br/>   
            <Button href='https://www.facebook.com/beyondmotivationliberty/videos/?ref=page_internal' target='_blank' style={{backgroundColor: '#D8A015', color: '#ffffff', width: '200px', border: 'none', borderRadius: '20px'}}>
                Watch more videos
            </Button>
        </Container>
        <br/><br/><br/>
      </div>
    );
  }

};
  
export default withCookies(BeyondMotivation);